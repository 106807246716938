import * as React from 'react';
import { Timeline as AntTimeline } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Timeline.module.scss';

const TimelineComp = ({ children, mode, className, ...rest }) => {
	return (
		<AntTimeline
			className={clsx(classes.timelineClasses, className)}
			mode={mode}
			{...rest}
		>
			{children}
		</AntTimeline>
	);
};

TimelineComp.propTypes = {
	children: PropTypes.node,
	mode: PropTypes.oneOf(['left', 'alternate', 'right']),
	className: PropTypes.string,
};

export const Timeline = React.memo(TimelineComp);
