import * as React from 'react';
import { Switch as AntSwitch } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Switch.module.scss';

const SwitchComp = ({
	className,
	onChange,
	size,
	disabled,
	checked,
	...rest
}) => {
	return (
		<AntSwitch
			className={clsx(className, classes[size])}
			onChange={onChange}
			size={size}
			disabled={disabled}
			checked={checked}
			{...rest}
		/>
	);
};

SwitchComp.propTypes = {
	onChange: PropTypes.func,
	size: PropTypes.oneOf(['small', 'default']),
	disabled: PropTypes.bool,
	checked: PropTypes.bool,
};

export const Switch = React.memo(SwitchComp);
