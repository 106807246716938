import * as React from 'react';

export const Search = React.memo(
	({ size = 16, color = '#6B7280', className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox='0 0 24 24'
				fill="none"
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				{...rest}
			>
				<path
					d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.84871 15.3729 11.551 14.3199 12.9056L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12.9056 14.3199C11.551 15.3729 9.84871 16 8 16C3.58172 16 0 12.4183 0 8Z"
					fill={color}
				/>
			</svg>
		);
	}
);

Search.displayName = 'Search';
