import * as React from 'react';
import { Tabs as AntTabs } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Tabs.module.scss';

const TabComp = (props) => {
	const {
		className = '',
		items = [],
		onChange = () => {},
		fullWidth = false,
		...rest
	} = props;
	return (
		<AntTabs
			className={clsx(
				classes.tabs,
				fullWidth && classes.fullWidth,
				className
			)}
			defaultActiveKey="1"
			onChange={onChange}
			items={items}
			{...rest}
		></AntTabs>
	);
};

TabComp.propTypes = {
	className: PropTypes.string,
	items: PropTypes.array,
	onChange: PropTypes.func,
};

export const Tabs = React.memo(TabComp);
