import { useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { getInitials } from 'utils/utils';

import { AvatarName, AvatarNameV2, Image } from 'components/atoms';

export function UserIcon({ image, size = 24 }) {
	return image ? (
		<Image
			src={image}
			width={size}
			height={size}
			className="tw-rounded-full tw-border tw-border-solid tw-border-color-backdrop"
			alt="user-icon"
		/>
	) : (
		<Avatar size={size} icon={<UserOutlined />} />
	);
}

export function ProfileIcon({
	img,
	className,
	name,
	height = 28,
	width = 28,
	version = 1,
	size = 'normal',
}) {
	const [imageError, setImageError] = useState(false);
	const handleImageError = () => {
		setImageError(true);
	};
	return (
		<div
			style={{ height: height, width: width }}
			className="tw-rounded-full tw-flex tw-items-center"
		>
			{imageError || !img ? (
				version === 1 ? (
					<AvatarName
						className={className}
						text={getInitials(name)}
					/>
				) : (
					<AvatarNameV2
						className={className}
						text={getInitials(name)}
						size={size}
					/>
				)
			) : (
				<img
					src={img}
					style={{ height: height, width: width }}
					alt="Profile Icon"
					className={'tw-rounded-full tw-object-cover'}
					onError={handleImageError}
				/>
			)}
		</div>
	);
}
