import * as React from 'react';

export const Flight = React.memo(
	({ size = 16, color = '#111827', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				{...className}
				{...rest}
			>
				<path
					stroke={color}
					strokeLinejoin="round"
					strokeWidth="1.5"
					d="M1.75 10.35l3.89 3.89.423-.424c.38-.38.474-.961.234-1.443l-.304-.608L8.82 9.644l2.652 4.773 1.254-1.255c.318-.317.44-.78.323-1.213l-1.4-5.134 1.993-1.993c.314-.314.534-.714.573-1.156.05-.559.046-1.31-.268-1.624-.315-.314-1.066-.318-1.624-.269-.442.04-.842.26-1.156.574L9.175 4.34l-4.97-1.242a1.25 1.25 0 00-1.188.329L1.75 4.694l4.596 2.475-2.121 2.828-.609-.304a1.25 1.25 0 00-1.442.234l-.424.424z"
				></path>
			</svg>
		);
	}
);

Flight.displayName = 'Flight';
